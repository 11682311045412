import Vue from "vue";
import axios from "axios";
import qs from "qs";
import store from "../store/index";
import modules from "./modules/index";

const base_url = process.env.VUE_APP_BACKEND_URL || process.env.BASE_URL;
console.log("baseURL", base_url);

const adapter = axios.create({
  baseURL: base_url,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

function addAuthHeader(adapter) {
  return (adapter.defaults.headers.common["Authorization"] = "Bearer " + store.state.account.auth_token);
}

export const backend = {
  index: (url, params, { encodeNestedData = false } = {}) => {
    addAuthHeader(adapter);

    // In some cases you want to send nested data as index params { filters: { a: 1 } }
    // To ensure it will be sent as object you need to pass flag encodeNestedData: true.
    // Without this flag data will be sent to backend as string which is not correct: { "filters"=>"{\"a\":1}" }
    if (encodeNestedData) {
      const nestedParams = qs.stringify(params.params, { arrayFormat: "brackets" });
      return adapter.get(`${url}?${nestedParams}`);
    } else {
      return adapter.get(url, params);
    }
  },
  show: (url, id, params) => {
    addAuthHeader(adapter);
    return adapter.get(url + "/" + id, params);
  },
  create: (url, id = null, params) => {
    addAuthHeader(adapter);
    params = modules["formData"].jsonToFormData(params);
    return adapter.post(url, params, {
      headers: modules["formData"].headers,
    });
  },
  update: (url, id, params) => {
    addAuthHeader(adapter);
    params = modules["formData"].jsonToFormData(params);
    return adapter.patch(url + "/" + id, params, {
      headers: modules["formData"].headers,
    });
  },
  destroy: (url, id) => {
    addAuthHeader(adapter);
    return adapter.delete(url + "/" + id);
  },
  sign_in: (url, params) => {
    addAuthHeader(adapter);
    return adapter.post(url, params);
  },
  sign_out: url => {
    return adapter.delete(url);
  },
  recovery_password: (url, params) => {
    return adapter.post(url, params);
  },
  reset_password: (url, params) => {
    return adapter.put(url, params);
  },
  collection: (url, params) => {
    addAuthHeader(adapter);
    params = modules["formData"].jsonToFormData(params);
    return adapter.post(url, params, {
      headers: modules["formData"].headers,
    });
  },
  // Для авторизации через SAML. Он принимает параметр csrf и работает только через созданную форму.
  // Там сразу редирект на страницу SAML по этому форма удаляется после выполнения запроса
  auth_with_csrf: (url, csrf) => {
    const form = document.createElement("form");
    const tokenInput = document.createElement("input");
    const csrfToken = csrf;
    tokenInput.value = csrfToken;
    tokenInput.name = "authenticity_token";
    form.action = url;
    form.hidden = true;
    form.method = "POST";

    form.appendChild(tokenInput);
    document.body.appendChild(form);

    form.submit();
  },
  put: (url, params) => {
    addAuthHeader(adapter);
    params = modules["formData"].jsonToFormData(params);
    return adapter.put(url, params, {
      headers: modules["formData"].headers,
    });
  },
  patch: (url, params, { jsonRequest } = { jsonRequest: false }) => {
    addAuthHeader(adapter);

    let headers;

    if (jsonRequest) {
      // pure json request
      headers = modules["formData"].newHeaders;
    } else {
      // form data
      params = modules["formData"].jsonToFormData(params);
      headers = modules["formData"].headers;
    }

    return adapter.patch(url, params, { headers });
  },
};

Vue.prototype.$backend = backend;

export default backend;
